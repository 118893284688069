/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f4f8;
    color: #333;
    line-height: 1.6;
}

h1, h2 {
    color: #004080;
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5em;
    margin-top: 30px;
}

h2 {
    font-size: 2em;
    margin-top: 20px;
}

/* Section Styles */
section {
    margin: 20px auto;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

/* Report Selection Buttons */
.report-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

.report-buttons button {
    background-color: #0066cc;
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    width: 100%;
    max-width: 400px;
    transition: background-color 0.3s ease;
}

.report-buttons button:hover {
    background-color: #004080;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
}

th, td {
    border: 1px solid #dddddd;
    padding: 12px;
    text-align: left;
    word-wrap: break-word;
}

th {
    background-color: #0066cc;
    color: white;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #e0e0e0;
}

/* Responsive Table */
@media screen and (max-width: 768px) {
    table, thead, tbody, th, td, tr {
        display: block;
        width: 100%;
    }

    th, td {
        padding: 10px;
        font-size: 10px;
        box-sizing: border-box;
    }

    tr {
        margin-bottom: 15px;
        display: block;
        border-bottom: 2px solid #dddddd;
    }

    td {
        display: flex;
        justify-content: space-between;
        text-align: right;
        border-bottom: 1px solid #eeeeee;
        position: relative;
        padding-left: 50%;
    }

    td:before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }
}

/* Chart Styles */
canvas {
    margin-top: 40px;
    max-width: 100%;
    height: auto;
}

/* Footer Styles */
footer {
    text-align: center;
    padding: 20px;
    background-color: #004080;
    color: white;
    position: relative;
    bottom: 0;
    width: 100%;
}

footer p {
    margin: 0;
    font-size: 0.9em;
}

/* Additional styles for companyID input */
.company-id-input {
    margin-bottom: 20px;
    text-align: center;
}

.company-id-input label {
    font-weight: bold;
    color: #004080;
    margin-bottom: 10px;
    display: block;
}

.company-id-input input {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 1em;
    width: 80%;
    max-width: 400px;
    margin-bottom: 20px;
}
